export const ROUTES = [
  {
    id: 'london-jakarta',
    value: '🇬🇧 UK - 🇮🇩 Indonesia',
  },
  {
    id: 'utrecht-jakarta',
    value: '🇳🇱 Netherlands - 🇮🇩 Indonesia',
  },
  {
    id: 'seattle-jakarta',
    value: '🇺🇸 United States - 🇮🇩 Indonesia',
  },
  {
    id: 'plympton-jakarta',
    value: '🇦🇺 Australia - 🇮🇩 Indonesia',
  },
  {
    id: 'tokyo-jakarta',
    value: '🇯🇵 Japan - 🇮🇩 Indonesia',
  },
  {
    id: 'singapore-jakarta',
    value: '🇸🇬 Singapore - 🇮🇩 Indonesia',
  },
  {
    id: 'jakarta-london',
    value: '🇮🇩 Indonesia - 🇬🇧 UK',
  },
  {
    id: 'jakarta-tokyo',
    value: '🇮🇩 Indonesia - 🇯🇵 Japan',
  },
  {
    id: 'changwon-jakarta',
    value: '🇰🇷 South Korea - 🇮🇩 Indonesia',
  },
]

export const ORDER_CATEGORIES = [
  'Bag',
  'Adult Shoes',
  'Children Shoes',
  'Baby Shoes',
  'Clothes',
  'Food',
  'Spare parts',
  'Others',
]

export const INTERCOM_APP_ID = 'toagkogd'

export const WAREHOUSE_DATA = {
  UK: [
    {
      title: 'UK - Indonesia',
      subtitle: 'IDR 425,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `Cindy {{name}} Charisa
6 Eden House
20-22 Deptford High Street
London SE8 4AF
+447415777087

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `<£500: 5%
£501-£1000: 4%
>£1000: 3%

Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  Netherlands: [
    {
      title: 'Netherlands - Indonesia',
      subtitle: 'IDR 425,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `Anindya {{name}} Permatasari
Kaatstraat 27, 3513 BV, Utrecht
+31619775426

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `<€500: 5%
€501-€1000: 4%
€1000: 3%

Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  USA: [
    {
      title: 'USA - Indonesia',
      subtitle: 'IDR 425,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `Siti Salwiah  c/o {{name}}
102 lark court
Apartment 102
Novato, California 94947

415-7206134

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `<$500: 5%
$501-$1000: 4%
$1000: 3%

Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  Australia: [
    {
      title: 'Australia - Indonesia',
      subtitle: 'IDR 400,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `Rurie {{name}} Suryadewi
3 / 36 Nottingham Cres
Glandore 5037
South Australia
+61 416442313

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `<AU$500: 5%
AU$501-AU$1000: 4%
AU$1000: 3%

Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  Singapore: [
    {
      title: 'Buying Service Only',
      subtitle: 'IDR 325,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `<SG$500: 5%
SG$501-SG$1000: 4%
SG$1000: 3%`,
    },
  ],
  Japan: [
    {
      title: 'Japan - Indonesia',
      subtitle: 'IDR 400,000 / kg (extra IDR 75,000 for the first 5kg)',
      description: `Recipient Address is available by request. Please contact us.

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `<100,000¥: 5%
>100,000¥: 4%

Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  Indonesia: [
    {
      title: 'Indonesia - UK',
      subtitle: '£17.5 / kg',
      description: `Novandyta UK({{name}})
Tebet Timur Dalam 2E/10
Jakarta 12820
08170003511
`,
    },
    {
      title: 'Indonesia - Japan',
      subtitle: '¥2,230 / kg',
      description: `Novandyta JP({{name}})
Tebet Timur Dalam 2E/10
Jakarta 12820
08170003511

make sure you put your own information (name, address, phone number, etc) for the billing address, orderer information or account registration`,
    },
    {
      title: 'Buying Service',
      subtitle: null,
      description: `All price range: 5%
      
      Please send your order receipt to hello@kalir.co or via Live Chat. The receipt should contain :
1. Image of the item(s)
2. Size (if applicable)
3. Price 
4. Shipping address
5. Billing address`,
    },
  ],
  'South Korea': [
    {
      title: 'South Korea - Indonesia',
      subtitle: 'IDR 400,000 / kg (T&C Apply)',
      description: `Buying Service Only`,
    },
  ],
}

export const FLAGS = {
  UK: '🇬🇧 ',
  Netherlands: '🇳🇱',
  USA: '🇺🇸',
  Australia: '🇦🇺',
  Singapore: '🇸🇬',
  Japan: '🇯🇵',
  Indonesia: '🇮🇩',
  'South Korea': '🇰🇷',
}

export const KALIR_COOKIE_NAME = 'kalir-session'
